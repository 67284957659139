<template>
  <!--begin::Filter-->
  <div
    class="menu menu-sub menu-sub-dropdown w-200px w-md-250px mt-2"
    data-kt-menu="true"
  >
    <!--begin::Header-->
    <div class="px-7 py-5">
      <div class="fs-5 text-gray-900 fw-bold">{{ $t('pages.items.filter.title') }}</div>
    </div>
    <!--end::Header-->

    <!--begin::Menu separator-->
    <div class="separator border-gray-200"></div>
    <!--end::Menu separator-->

    <!--begin::Form-->
    <div class="px-7 py-5">
      <!--begin::Input group-->
      <div class="mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semibold">{{ $t('pages.items.filter.groups') }}:</label>
        <!--end::Label-->

        <!--begin::Input-->
        <div>
          <select
            class="form-select form-select-solid select2-hidden-accessible"
            name="group"
            v-model="data.groups"
          >
            <option value="0">{{ $t('pages.items.filter.allGroups') }}</option>
            <option
              v-for="group in groups"
              :key="group.id"
              :value="group.id"
            >
              {{ group.title }}
            </option>
          </select>
        </div>
        <!--end::Input-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semibold">{{ $t('pages.items.filter.platforms') }}:</label>
        <!--end::Label-->

        <!--begin::Options-->
        <div class="d-flex flex-wrap">
          <label
            v-for="platform in platforms"
            :key="platform.id"
            class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-3"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="platform.id"
              v-model="data.platforms"
            />
            <span class="form-check-label user-select-none"> {{ platform.title }} </span>
          </label>
        </div>
        <!--end::Options-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-6">
        <!--begin::Label-->
        <label class="form-label fw-semibold">{{ $t('pages.items.filter.wishprice') }}:</label>
        <!--end::Label-->
      
        <div class="d-flex flex-wrap">
          <!--begin::Switch-->
          <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid me-5 mb-5">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="data.priceComparison"
              />
              <span class="form-check-label user-select-none">{{ $t('pages.items.filter.executed') }}</span>
            </label>
          </div>
          <!--end::Switch-->
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="mb-6">
        <!--begin::Label-->
        <label class="form-label fw-semibold">{{ $t('pages.items.filter.status') }}:</label>
        <!--end::Label-->

        <div class="d-flex flex-wrap">
          
          <!--begin::Switch-->
          <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid me-5 mb-5">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="checkbox"
                name="archive"
                v-model="data.status.archive"
                :disabled="data.status.any"
              />
              <span class="form-check-label user-select-none">{{ $t('pages.items.filter.archive') }}</span>
            </label>
          </div>
          <!--end::Switch-->

          <!--begin::Switch-->
          <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid me-5 mb-5">
            <label class="form-check-label">
              <input
                class="form-check-input"
                type="checkbox"
                name="active"
                v-model="data.status.active"
                :disabled="data.status.any"
              />
              <span class="form-check-label user-select-none">{{ $t('pages.items.filter.active') }}</span>
            </label>
          </div>
          <!--end::Switch-->
          
          <label class="form-check form-check-sm form-check-custom form-check-solid ms-3 mb-5">
            <input
              class="form-check-input"
              type="checkbox"
              value="any"
              v-model="data.status.any"
            />
            <span class="form-check-label user-select-none"> {{ $t('pages.items.filter.any') }} </span>
          </label>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-sm btn-light btn-active-light-primary"
          @click="resetFilters"
        > {{ $t('pages.items.filter.reset') }} </button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Form-->
  </div>
  <!--end::Filter-->
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export const initialFilters = {
  groups: "0",
  platforms: [],
  status: { active: true, any: false, archive: false },
  priceComparison: false
};

export default defineComponent({
  name: "FilterItems",
  props: {
    platforms: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const data = ref({ ...props.filters });

    // Наблюдатель для обновления data при изменении props.filters
    watch(() => props.filters, (newFilters) => {
      data.value = { ...newFilters };
    }, { deep: true });
    
    // Функция для глубокого сброса фильтров
    const resetFilters = () => {
      data.value = { ...initialFilters };
      emit('resetFilters');
      
      // Перенаправляем на /search
      router.push('/search');
    };

    watch(data, (newData) => {
      if (JSON.stringify(newData) !== JSON.stringify(props.filters)) {
        emit('applyFilters', newData);
      }
    }, { deep: true });
    
    // Обновление статусов на основе логики
    watch(
      () => data.value.status.archive,
      (newVal) => {
        if (newVal) {
          data.value.status.active = false;
        }
      }
    );
    
    watch(
      () => data.value.status.active,
      (newVal) => {
        if (newVal) {
          data.value.status.archive = false;
        }
      }
    );
  
    return {
      data,
      resetFilters
    };
  }
});
</script>
