<template>
  <button
    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
    data-kt-menu-trigger="click"
    data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="top-end"
    :disabled="isSettingDisabled"
  >
    <KTIcon icon-name="setting-4" icon-class="fs-3" />
  </button>

  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-4 w-200px mt-2 fs-6"
    data-kt-menu="true"
  >    
    <!--begin::Menu edit-->
    <div
      v-if="dataStore && dataStore.id"
      class="menu-item px-5"
    >
      <div 
        data-bs-toggle="modal"
        data-bs-target="#modal_groups"
        class="menu-link px-4"
        @click="handleModal"
      > 
        <KTIcon icon-name="pencil" icon-class="fs-3 me-3" />
        {{ $t('actions.edit') }}
      </div>
    </div>
    <!--end::Menu edit-->

    <!--begin::Menu delete-->
    <div class="menu-item px-5">
      <DeleteItem 
        class="menu-link text-danger px-4"
        :data="dataStore"
        type="group"
        @group-deleted="handleDelete"
      >
        <KTIcon icon-name="trash" icon-class="fs-3 me-3 text-danger" />
        {{ $t('actions.delete') }}
      </DeleteItem>
    </div>
    <!--end::Menu delete-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";

import DeleteItem from "@/components/actions/DeleteItem.vue";

export default defineComponent({
  name: "actions-search-dropdown",
  components: {
    DeleteItem,
  },
  props: {
    dataStore: {
      type: Object,
      required: true
    },
    isSettingDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  emits: [
    'data-modal',
    'data-deleted',
  ],
  setup(props, { emit }) {
    const { t } = useI18n();

    const handleModal = () => {
      emit('data-modal', props.dataStore);
    };
    
    const handleDelete = (itemId) => {
      emit('data-deleted', itemId);
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      handleModal,
      handleDelete,
    };
  }
});
</script>
