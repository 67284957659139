import { defineStore } from 'pinia';
import ApiService from '@/core/services/ApiService';
import { handlerApiError, updateOrAddItems } from '@/utils/helpers/storeHelpers';

interface FetchParams {
  limit?: number;
  id?: number;
}

interface PlatformChartData {
  date: string[];
  item: (number | null)[];
  link: (number | null)[];
}

export const useItemStore = defineStore('item', {
  state: () => ({
    searchs: [] as any[],
    groups: [] as any[],
    platforms: [] as any[],
    items: [] as any[],
    countSearch: 0 as number,
    countGroup: 0 as number,
    chartPlatforms: {
      date: [] as string[],
      item: [] as (number | null)[],
      link: [] as (number | null)[]
    } as PlatformChartData,
    errors: [] as string[],
  }),
  getters: {
    priceComparison: () => (item: any) => {
      const bestOfferPrice = parseFloat(item.best_offer?.min_price);
      const wishprice = parseFloat(item.wishprice);
      return !isNaN(bestOfferPrice) && !isNaN(wishprice) && wishprice >= bestOfferPrice;
    },
  },
  actions: {
    /**
     * Асинхронно запрашивает и обрабатывает данные для поиска, групп и платформ с использованием API.
     *
     * @param params - Параметры для запроса, содержащие `id` для получения конкретного элемента поиска 
     * или `limit` для ограничения количества получаемых элементов.
     * @returns `void`
     *
     * Функция формирует команду поиска на основе параметров `id` или `limit` и отправляет их вместе 
     * с командами для получения групп и платформ. После успешного запроса:
     * - Обновляет массив `searchs` элементами, полученными из `getSearch`, и счетчик `countSearch`.
     * - Обновляет массив `groups`, содержащий группы, полученные из `getGroup`, и счетчик `countGroup`.
     * - Сохраняет данные для `platforms` из ответа `getPlatform`.
     * - В случае ошибки выполняет вызов `handlerApiError` для обработки и отображения ошибок.
     */
    async fetchSearchData(params: FetchParams): Promise<void> {
      return await ApiService.get('pulse.table.itemSearch.list.json', params)
        .then(({ data }) => {
          // Обработка данных для поиска
          const tempSearchs = data.result?.items;
          const tempCountSearch = data.result?.count;
          
          if (tempSearchs?.length) {
            if (tempSearchs.length === this.countSearch) {
              this.searchs = tempSearchs;
            } else {
              // Добавляем или обновляем элементы
              updateOrAddItems(this.searchs, tempSearchs, 'id');
            }
          }
          
          if (tempCountSearch !== undefined) {
            this.countSearch = tempCountSearch;
          }          
        })
        .catch(error => handlerApiError(this.errors, error));
    },
    
    /**
     * Асинхронно запрашивает данные части поиска с сервера и обновляет локальное хранилище `searchs`.
     *
     * @param params - Параметры для запроса данных, такие как идентификатор 'id' и лимит 'limit'.
     * @returns `void`
     *
     * Функция отправляет GET-запрос к API с указанными параметрами. После получения ответа:
     * - Обрабатывает массив элементов `items` из результата.
     * - Для каждого элемента:
     *   - Если элемент с таким же `id` уже существует в `this.searchs`, он заменяется новым значением.
     *   - Если элемента с таким `id` нет, он добавляется в `this.searchs`.
     * - Обновляет счетчик `countSearch` с количеством элементов в результате запроса.
     * - В случае ошибки выполнения запроса вызывает обработчик `handlerApiError`.
     */
    async fetchSearchPartData(params: FetchParams): Promise<void> {
      return await ApiService.get('pulse.table.itemSearch.list.json', params)
        .then(({ data }) => {
          // Обработка данных для поиска
          const tempSearchs = data.result?.items;
          const tempCountSearch = data.result?.count;
          
          if (tempSearchs?.length) {
            updateOrAddItems(this.searchs, tempSearchs, 'id');
          }

          if (tempCountSearch !== undefined) {
            this.countSearch = tempCountSearch;
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async fetchGroupData(params: FetchParams): Promise<void> {
      return await ApiService.get("pulse.table.group.list.json", params)
        .then(({ data }) => {
          // Обработка данных для групп
          const tempGroups = data.result?.groups;
          const tempCountGroup = data.result?.count;
          
          if (tempGroups?.length) {
            this.groups = tempGroups;
          }
          
          if (tempCountGroup !== undefined) {
            this.countGroup = tempCountGroup;
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async fetchItemData(params: FetchParams): Promise<void> {
      return await ApiService.get("pulse.table.items.list.json", params)
        .then(({ data }) => {          
          if (data.result?.length) {
            this.items = data.result;
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async fetchPlatformData(params: FetchParams): Promise<void> {
      return await ApiService.get("pulse.table.platform.list.json", params)
        .then(({ data }) => {
          if (data.result?.length) {
            this.platforms = data.result;
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async fetchPlatformChartData(): Promise<void> {
      return await ApiService.get("pulse.table.platform.chart.json")
        .then(({ data }) => {
          if (data.result) {
            this.chartPlatforms = data.result;
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async archiveSearch(params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await this.performDeleteRequest("pulse.table.itemSearch.archive", params, localizedError);
    },
        
    async clearImageSearch(params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await this.performDeleteRequest("pulse.table.itemSearch.cleanImage", params, localizedError);
    },
    
    async clearItems(params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await this.performDeleteRequest("pulse.table.items.clean", params, localizedError);
    },
    
    async clearAnalytics(params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await this.performDeleteRequest("pulse.table.price.clean", params, localizedError);
    },

    async deleteItem(params: FetchParams, isGroup: boolean, localizedError: string = ''): Promise<boolean> {
      const deleteUrl = isGroup ? 'pulse.table.group.delete' : 'pulse.table.itemSearch.delete';
      return await this.performDeleteRequest(deleteUrl, params, localizedError);
    },
    
    async deleteListItems(searchId: number, ids: number[], uniqueWords?: string[]): Promise<boolean> {
      return await ApiService.delete(
        "pulse.table.items.delete", searchId, ids, uniqueWords ?? []
      )
        .then(({ data }) => {
          return data.result ? true : false;
        })
        .catch(error => handlerApiError(this.errors, error, "deleteItems"));
    },
    
    async importSearchData(formData: object): Promise<boolean> {
      return await this.performPostRequest("pulse.table.itemSearch.import", formData, "importSearch");
    },

    async addSearch(formData: object): Promise<boolean> {
      return await this.performPostRequest("pulse.table.itemSearch.add", formData, "addSearch");
    },

    async editSearch(formData: object): Promise<boolean> {
      return await this.performPostRequest("pulse.table.itemSearch.edit", formData, "editSearch");
    },

    async addGroup(formData: object): Promise<boolean> {
      return await this.performPostRequest("pulse.table.group.add", formData, "addGroup");
    },

    async editGroup(formData: object): Promise<boolean> {
      return await this.performPostRequest("pulse.table.group.edit", formData, "editGroup");
    },
    
    async performDeleteRequest(url: string, params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await ApiService.delete(url, params)
        .then(({ data }) => {
          return data.result ? true : false;
        })
        .catch(error => handlerApiError(this.errors, error, localizedError));
    },

    async performPostRequest(url: string, formData: object, localizedError: string): Promise<boolean> {
      return await ApiService.post(url, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(({ data }) => {
        return data.result ? true : false;
      })
      .catch(error => {
        handlerApiError(this.errors, error, localizedError);
        return false;
      });
    },
  },
});
