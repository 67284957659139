<template>
  <div
    v-for="(item, index) in placeholderItems"
    :key="index"
    :class="['py-5', { 'border-bottom': index !== placeholderItems.length - 1 }]"
  >
    <div class="d-flex flex-column w-100 gap-3">
      <div class="rounded-1 placeholder bg-secondary placeholder-lg fs-5 col-11"></div>
      <div class="flex-row">
        <div class="rounded-1 placeholder bg-secondary placeholder-sm col-4 me-5"></div>
        <div class="rounded-1 placeholder bg-secondary placeholder-sm col-4"></div>
      </div>
    </div>
  
    <div class="d-flex flex-row mt-7">
      <div class="d-flex align-items-center">
        <div class="symbol symbol-70px placeholder bg-secondary">
          <span class="symbol-label placeholder bg-secondary"></span>
        </div>
      </div>
  
      <div class="d-flex flex-row flex-grow-1 align-items-center ms-10">
        <div class="d-flex flex-column gap-4 w-50 ms-auto">
          <div class="rounded-1 placeholder bg-secondary placeholder-lg col-7"></div>
          <div class="rounded-1 placeholder bg-secondary placeholder-sm col-7"></div>
          <div class="rounded-1 placeholder bg-secondary placeholder-sm col-4"></div>
        </div>
        <div class="rounded-1 placeholder bg-secondary placeholder-lg h-50px w-50px mx-auto"></div>
      </div>
    </div>
  
    <div v-if="platform" class="card card-flush mt-7 py-5 px-7">
      <div class="d-flex justify-content-between gap-4 border-bottom border-gray-200 pb-5 fs-9">
        <div class="rounded-1 placeholder bg-secondary col-3"></div>
        <div class="rounded-1 placeholder bg-secondary col-3 text-center"></div>
        <div class="rounded-1 placeholder bg-secondary col-3 text-end"></div>
      </div>
      
      <div class="d-flex justify-content-between gap-4 mt-5">
        <div class="rounded-1 placeholder bg-secondary col-3"></div>
        <div class="rounded-1 placeholder bg-secondary col-3"></div>
        <div class="rounded-1 placeholder bg-secondary col-3"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'placeholder-pages-search-mobile',
  props: {
    length: {
      type: Number,
      required: true,
    },
    platform: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  setup(props) {
    const placeholderItems = Array.from({ length: props.length }, () => ({}));

    return {
      placeholderItems,
    }
  },
});
</script>