<template>
  <div class="d-flex flex-column flex-sm-row align-items-sm-center">
    <div class="d-flex flex-row order-2 order-sm-1 mt-7 mt-sm-0">
      <router-link
        :to="`/search/${item.id}/items`"
        class="symbol symbol-75px"
        :aria-label="item.title"
      >
        <DynamicSymbol 
          :title="item.title" 
          :image="item.image" 
          fontSizeClass="fs-2"
        />
        <SymbolStatus
          :status="item.status"
          :archive="item.archive"
          :links="item.isLinks"
        />
      </router-link>
      
      <template v-if="isMobile">
        <div class="d-flex flex-grow-1 justify-content-around align-items-center ms-14">
          <BestOffer :item="item" />
          <SearchChart :chart-data="item.chart" />
        </div>
      </template>
    </div>

    <div class="d-flex flex-column order-1 order-sm-2 ms-0 ms-sm-5 me-15 me-sm-0">
      <router-link :to="`/search/${item.id}/items`" :class="titleClass">
        {{ item.title }}
      </router-link>

      <div :class="[
        'd-flex',
        item.title.length > 50 ? 'flex-row' : 'flex-row flex-sm-column'
      ]">
        <GroupLink
          :item="item"
          :group="group"
          class="mt-2 me-4"
        />
        <WishPriceDisplay
          :item="item"
          class="mt-2"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useItemStore } from '@/stores/item';
import { isMobile } from "@/core/helpers/mobile";

import SymbolStatus from '@/components/items/SymbolStatus.vue';
import BestOffer from "@/components/items/BestOffer.vue";
import SearchChart from "@/components/chart/SearchChart.vue";
import DynamicSymbol from '@/components/formatters/DynamicSymbol.vue';
import GroupLink from '@/components/items/GroupLink.vue';
import WishPriceDisplay from '@/components/items/WishPriceDisplay.vue';

export default defineComponent({
  name: 'item-card',
  components: {
    SymbolStatus,
    BestOffer,
    SearchChart,
    DynamicSymbol,
    GroupLink,
    WishPriceDisplay,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const itemStore = useItemStore();

    // Используем функцию сравнения цены из хранилища
    const titleClass = computed(() => ({
      'fw-bold mb-1 text-hover-primary fs-5': true,
      'text-gray-800': !itemStore.priceComparison(props.item),
      'text-success': itemStore.priceComparison(props.item),
    }));

    return {
      isMobile,
      titleClass,
    };
  },
});
</script>
