<template>
  <div class="card card-flush">
    <div class="card-header flex-nowrap py-5 gap-0 gap-md-5">
      <div class="card-title">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bold fs-3 mb-1">
            {{ $t('pages.groups.titleCard') }}
          </span>
          <span class="text-muted mt-1 fw-semibold fs-7">
            {{ $t('pages.groups.formatter.total') }} 
            <SmoothCounter :targetValue="itemStore.countGroup" />
            <EndingFormatter
              :number="itemStore.countGroup"
              :single="$t('pages.groups.formatter.single')"
              :few="$t('pages.groups.formatter.few')"
              :many="$t('pages.groups.formatter.many')"
            />
          </span>
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#modal_groups"
          class="btn btn-sm btn-primary"
          :disabled="isAddGroupButtonDisabled"
          @click="openAddGroupModal"
        >
          <KTIcon icon-name="plus" icon-class="fs-3" />
          {{ $t('pages.groups.addGroup') }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0 pb-5">
      <Datatable
        v-if="itemStore.groups.length > 0"
        class="table align-middle table-row-dashed gy-3"
        :header="tableHeader"
        :data="itemStore.groups"
        :items-per-page="itemsPerPage"
        :items-per-page-dropdown-enabled="itemsPerPageDropdown"
        :emptyTableText="$t('dataTable.emptyTable')"
        @on-items-per-page-change="handleItemsPerPage"
        ref="tableData"
      >
        <template v-slot:title="{ row: group }">
          <div class="d-flex align-items-center min-w-200px">

              <router-link 
                :to="{ name: 'search', query: { group: group.id } }" 
                class="symbol symbol-50px"
                :aria-label="group.title"
              >
                <DynamicSymbol 
                  :title="group.title" 
                  :image="group.image" 
                  fontSizeClass="fs-3" 
                />
              </router-link>
              
              <div class="d-flex flex-column ms-5">
                <router-link 
                  :to="{ name: 'search', query: { group: group.id } }" 
                  class="d-flex text-gray-800 text-hover-primary fs-5 fw-bold mb-2" 
                  data-kt-ecommerce-category-filter="category_name">
                  <span class="text-nowrap">{{ group.title }}</span>
                  <span class="badge badge-secondary ms-2">
                    {{ group.count }}
                  </span>
                </router-link>
                <div class="text-muted fs-7">
                  {{ group.descr }}
                </div>
              </div>
              
          </div>
        </template>

        <template v-slot:actions="{ row: group }">
          <ActionsGroup
            :data-store="group"
            :is-setting-disabled="isSettingDisabled"
            @data-modal="openEditGroupModal"
            @data-deleted="handleGroupDelete"
          />
        </template>
      </Datatable>
      
      <Placeholder
        v-else
        :length="3"
        :table-header="tableHeader"
        :items-per-page="itemsPerPage"
      />
    </div>
  </div>
  
  <GroupsModal
    :editMode="editMode"
    :groupData="formData"
    @data-updated="fetchGroupsData"
  />
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useConfigStore } from "@/stores/config";
import { useAuthStore } from "@/stores/auth";
import { useItemStore } from "@/stores/item";
import { initGroupData } from "@/utils/helpers/itemHelpers";
import cloneDeep from 'lodash/cloneDeep';

import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import GroupsModal from "@/components/modals/forms/GroupsModal.vue";
import EndingFormatter from "@/components/formatters/EndingFormatter.vue";
import DynamicSymbol from '@/components/formatters/DynamicSymbol.vue';
import Placeholder from '@/components/placeholder/PagesGroup.vue';
import ActionsGroup from "@/components/dropdown/ActionsGroup.vue";
import SmoothCounter from '@/components/formatters/SmoothCounter.vue'

export default defineComponent({
  name: "groups",
  components: {
    Datatable,
    GroupsModal,
    EndingFormatter,
    DynamicSymbol,
    Placeholder,
    ActionsGroup,
    SmoothCounter,
  },
  setup() {
    const { t } = useI18n();
    const bodyStore = useConfigStore();
    const userStore = useAuthStore();
    const itemStore = useItemStore();
    const tableData = ref(null);
    const editMode = ref(false);
    const formData = ref({});
    const itemsPerPage = ref(bodyStore.getLayoutConfig('datatable.itemsPerPage'));
    const isSettingDisabled = ref(true);

    const itemsPerPageDropdown = computed(() => {
      return itemStore.groups?.length >= 10;
    });

    const isAddGroupButtonDisabled = computed(() => 
      userStore.isSubscription('group', itemStore.groups?.length)
    );
    
    const tableHeader = ref([
      {
        columnName: t('pages.groups.titleTable.groups'),
        columnLabel: "title",
        sortEnabled: true,
      },
      {
        // columnName: t('pages.groups.titleTable.action'),
        columnLabel: "actions",
        sortEnabled: false,
        columnClass: 'min-w-50px'
      },
    ]);
        
    const fetchGroupsData = async () => {
      isSettingDisabled.value = true;
      await itemStore.fetchGroupData();
      isSettingDisabled.value = false;
    };

    onMounted(() => {
      fetchGroupsData();
    });
    
    const openAddGroupModal = () => {
      editMode.value = false;
      formData.value = { ...initGroupData() };
    };

    const openEditGroupModal = (group) => {
      editMode.value = true;
      formData.value = cloneDeep(group);
    };
    
    const handleGroupDelete = (groupId) => {
      tableData.value.data.splice(tableData.value.data.findIndex(item => item.id === groupId), 1);
    };
    
    const handleItemsPerPage = (val) => {
      bodyStore.setLayoutConfigProperty("datatable.itemsPerPage", val);
    };
    
    return {
      tableHeader,
      tableData,
      itemStore,
      fetchGroupsData,
      openAddGroupModal,
      openEditGroupModal,
      handleGroupDelete,
      editMode,
      formData,
      isAddGroupButtonDisabled,
      isSettingDisabled,
      handleItemsPerPage,
      itemsPerPage,
      itemsPerPageDropdown,
    };
  },
});
</script>
