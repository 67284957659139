<template>
  <!--begin::Step 2-->
  <div
    v-if="itemStore.platforms?.length > 0"
    data-kt-stepper-element="content"
  >
    <!--begin::Wrapper-->
    <div class="d-flex flex-column w-100">
      <!-- Опция поиска по названию -->
      <div class="d-flex flex-column mb-7 fv-row">
        <div class="d-flex flex-stack">
          <!-- Поле для ввода названия поиска -->
          <div
            v-if="isSearchByTitle"
            class="form-floating w-100 me-5 mb-n1"
            style="margin-top:-2px"
          >
            <Field
              type="text"
              id="nameSearchValue"
              class="form-control"
              :placeholder="$t('pages.items.modal.placeholder.title')"
              name="title"
              v-model="localItemData.title"
            />
            <label for="nameSearchValue">{{ $t('pages.items.modal.label.byTitle') }}</label>
          </div>

          <div v-else class="me-5">
            <label class="form-label">{{ $t('pages.items.modal.label.byTitle') }}</label>              
            <div class="text-muted fs-7">
              {{ $t('pages.items.modal.subLabel.byTitle') }}
            </div>
          </div>
          
          <label class="form-check form-switch form-check-custom form-check-solid">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="isSearchByTitle"
              @change="toggleByTitle"
              :disabled="isEmpty(localItemData.title)"
            />
            <span class="form-check-label fw-semibold text-gray-500">
              {{ $t('pages.items.modal.other.byTitle') }}
            </span>
          </label>
        </div>
        
        <ErrorMessage
          v-if="isSearchByTitle"
          class="fv-plugins-message-container invalid-feedback"
          name="title"
        />
      </div>

      <div
        v-for="platform in itemStore.platforms"
        :key="platform.id"
        class="d-flex flex-column mb-5 mb-md-7 fv-row"
      >
        <div 
          v-if="localItemData.platform?.[platform.id]?.links"
          v-for="(link, linkID) in localItemData.platform[platform.id].links" 
          :key="`platform_${platform.id}_${linkID}`" 
          class="d-flex flex-column mb-2"
        >
          <div class="d-flex align-items-center gap-2">
            <!-- Кнопка клика по ссылке -->
            <button
              type="button"
              :class="[
                'btn btn-icon',
                !isLinkValid(platform.id, linkID) ? 'btn-light-secondary' : 'btn-light-success'
              ]"
              @click="clickLink(platform.id, linkID)"
              :disabled="!isLinkValid(platform.id, linkID)"
            >
              <KTIcon icon-name="click" icon-class="fs-3" />
            </button>

            <!-- Поле для ввода ссылки -->
            <Field
              type="text"
              class="form-control"
              :placeholder="platform.url"
              :name="`platform.platform_${platform.id}.key_${linkID}`"
              v-model="localItemData.platform[platform.id].links[linkID]"
              :disabled="isChackByTitle"
            />

            <!-- Кнопка для добавления новой ссылки -->
            <button
              v-if="Object.keys(localItemData.platform[platform.id].links).indexOf(linkID) === 0"
              type="button"
              class="btn btn-icon btn-light-primary"
              @click="addLink(platform.id)"
              :disabled="(
                localItemData.platform[platform.id] && 
                Object.keys(localItemData.platform[platform.id].links).length >= (userStore.user?.subscription ? userStore.user?.subscription.maxLink : 1)) || 
                isSearchByTitle"
            >
              <KTIcon icon-name="plus" icon-class="fs-3" />
            </button>

            <!-- Кнопка для удаления ссылки -->
            <button
              v-else
              type="button"
              class="btn btn-icon btn-light-danger"
              @click="removeLink(platform.id, linkID)"
            >
              <KTIcon icon-name="trash" icon-class="fs-3" />
            </button>
          </div>

          <!-- Сообщение об ошибке валидации -->
          <ErrorMessage 
            class="fv-plugins-message-container invalid-feedback d-block mt-1"
            :key="`platform_${platform.id}_${linkID}`" 
            :name="`platform.platform_${platform.id}.key_${linkID}`" 
          />
        </div>

        <!-- Описание и ссылка на платформу -->
        <div class="text-muted fs-7">
          <span v-html="translatedLink(platform)"></span>
        </div>
      </div>
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Step 2-->
</template>

<script lang="ts">
import { defineComponent, watch, computed, ref } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";
import { 
  generateLink,
  generateSearchLink, 
  cleanLinkInput,
  isUrlMatchingPlatform,
  type ItemData,
  type Platform
} from "@/utils/helpers/itemHelpers";

export default defineComponent({
  name: "item-modal-step2",
  components: {
    ErrorMessage,
    Field,
  },
  props: {
    localItemData: {
      type: Object as () => ItemData,
      required: true
    },
    itemStore: {
      type: Object as () => ItemData,
      required: true
    },
  },
  setup(props) {
    const { t } = useI18n();
    const userStore = useAuthStore();

    // Сохраненные ссылки и флаг для предотвращения повторного сохранения
    const platformSavedLinks = ref<ItemData["platform"]>({});
    const platformSavedFlag = ref<number>(0);

    const isSearchByTitle = computed(() => props.localItemData.search_by_title === '1');
    const isChackByTitle = computed(() => isSearchByTitle.value && !isEmpty(props.localItemData.title));

    /**
     * Проверяет, является ли ссылка для заданной платформы и идентификатора ссылки валидной.
     * @param {string} platformID - Идентификатор платформы.
     * @param {string} linkID - Идентификатор ссылки.
     * @returns {boolean} true, если ссылка валидна.
     */
    const isLinkValid = computed(() => (platformID: string, linkID: string) => {
      if (!props.localItemData.platform) return false;
      const url = props.localItemData.platform[platformID]?.links[linkID];
      const platform = props.itemStore.platforms?.find(p => p.id === platformID);
    
      if (!url || !platform) return false;
    
      const finalUrl = generateLink(platform, url);
      return isUrlMatchingPlatform(finalUrl, platform);
    });

    /**
     * Добавляет новую ссылку для указанной платформы.
     * @param {string} platformID - Идентификатор платформы.
     */
    const addLink = (platformID: string) => {
      if (!platformID) return;

      const existingLinks = Object.keys(props.localItemData.platform[platformID].links);

      if (!existingLinks.length) {
        props.localItemData.platform[platformID].links = {'n0': ''};
      } else if (existingLinks.length < (userStore.user?.subscription ? userStore.user?.subscription.maxLink : 1)) {
        const newLinkID = generateNewLinkID(existingLinks);
        props.localItemData.platform[platformID].links[newLinkID] = '';
      }
    };

    /**
     * Генерирует новый идентификатор для ссылки на основе существующих идентификаторов.
     * @param {string[]} existingLinks - Массив существующих идентификаторов ссылок.
     * @returns {string} Новый идентификатор ссылки.
     */
    const generateNewLinkID = (existingLinks: string[]) => {
      const maxIndex = existingLinks.reduce((max, linkID) => {
        const match = linkID.match(/^n(\d+)$/);
        return match ? Math.max(max, parseInt(match[1], 10)) : max;
      }, -1);

      return `n${maxIndex + 1}`;
    };

    /**
     * Удаляет ссылку с указанным идентификатором.
     * @param {string} platformID - Идентификатор платформы.
     * @param {string} linkID - Идентификатор ссылки.
     */
    const removeLink = (platformID: string, linkID: string) => {
      if (Object.prototype.hasOwnProperty.call(props.localItemData.platform[platformID].links, linkID)) {
        delete props.localItemData.platform[platformID].links[linkID];
      }
    };

    /**
     * Открывает ссылку в новом окне.
     * @param {string} platformID - Идентификатор платформы.
     * @param {string} linkID - Идентификатор ссылки.
     */
    const clickLink = (platformID: string, linkID: string) => {
      const url = props.localItemData.platform[platformID]?.links?.[linkID];
      const platform = props.itemStore.platforms?.find((p) => p.id === platformID);

      if (platform && url) {
        const finalUrl = generateLink(platform, url);
        window.open(finalUrl, '_blank');
      }
    };

    /**
     * Создает HTML-ссылку для указанной платформы.
     * @param {Object} platform - Данные платформы.
     * @returns {string} HTML-ссылка для платформы.
     */
    const platformLink = (platform) => {
      return `<a href="${platform.url}" aria-label="${platform.title}" target="_blank">${platform.title}</a>`;
    };

    /**
     * Форматирует строку перевода с вставленной HTML-ссылкой.
     * @param {Object} platform - Данные платформы.
     * @returns {string} Строка перевода с HTML-ссылкой.
     */
    const translatedLink = (platform) => {
      return t('pages.items.modal.subLabel.link', { platform: platformLink(platform) });
    };
    
    /**
     * Проверяет, является ли строка пустой или содержит только пробелы.
     * @param value - строка для проверки.
     * @returns {boolean} true, если строка пустая или содержит только пробелы, иначе false.
     */
    const isEmpty = (value: string | null | undefined): boolean => {
      return !value || value.trim() === "";
    }
    
    /**
     * Переключает состояние byTitle и изменяет ссылки на платформе.
     * @returns {void}
     */
    const toggleByTitle = () => {
      // Переключаем состояние byTitle
      props.localItemData.search_by_title = isSearchByTitle.value ? '0' : '1';
    
      // Прерываем, если нет названия товара
      if (isEmpty(props.localItemData.title)) {
        return;
      }
      
      changePlatformsLinks(props.localItemData.title);
    }

    /**
     * Восстанавливает сохраненные ссылки на платформе из platformSavedLinks.
     * @returns {void}
     */
    const restorePlatformsLinks = () => {
      props.localItemData.platform = JSON.parse(JSON.stringify(platformSavedLinks.value));
    };
    
    /**
     * Проверяет, отличается ли новая сгенерированная ссылка от первой текущей ссылки для данной платформы,
     * корректно обрабатывая URL-кодирование.
     * @param platformId - Идентификатор платформы для сравнения.
     * @param newLink - Новая сгенерированная ссылка для сравнения.
     * @returns {boolean} - true, если новая ссылка отличается от первой сохраненной, иначе false.
     */
    const isNewLinkDifferent = (platformId: string, newLink: string): boolean => {
      const currentLink = Object.values(props.localItemData.platform?.[platformId]?.links || {})[0];
      
      // Декодируем URL для корректного сравнения
      const decodedCurrentLink = currentLink ? decodeURIComponent(currentLink) : null;
      const decodedNewLink = decodeURIComponent(newLink);
      
      return decodedCurrentLink !== decodedNewLink.trim();
    };
    
    /**
     * Сохраняет исходные ссылки для текущего товара один раз, если они еще не сохранены.
     */
    const saveOriginalLinks = () => {
      if (platformSavedFlag.value !== props.localItemData.id) {
        platformSavedLinks.value = JSON.parse(JSON.stringify(props.localItemData.platform));
        platformSavedFlag.value = props.localItemData.id;
      }
    };
    
    /**
     * Генерирует и устанавливает новую ссылку на основе шаблона, если она отличается от текущей.
     * Если новая ссылка совпадает с одной из оригинальных ссылок, восстанавливает оригинальный ключ.
     * @param platformId - Идентификатор платформы.
     * @param newLink - Сгенерированная новая ссылка для сравнения и установки.
     */
    const updateLinkIfDifferent = (platformId: string, newLink: string) => {
      // Проверяем, отличается ли новая ссылка от текущей
      if (newLink && isNewLinkDifferent(platformId, newLink)) {
        const originalLinks = platformSavedLinks.value[platformId]?.links || {};
    
        // Ищем ключ оригинальной ссылки, если новая совпадает с одной из сохраненных
        const originalLinkKey = Object.keys(originalLinks).find(
          key => decodeURIComponent(originalLinks[key]) === decodeURIComponent(newLink).trim()
        );
    
        if (originalLinkKey) {
          // Восстанавливаем оригинальный ключ
          props.localItemData.platform[platformId].links = { [originalLinkKey]: newLink };
        } else {
          // Устанавливаем новую ссылку с ключом 'n0'
          props.localItemData.platform[platformId].links = { 'n0': newLink };
        }
      } else if (platformSavedLinks.value[platformId]?.links) {
        // Восстанавливаем исходные ссылки, если они совпадают
        props.localItemData.platform[platformId].links = {
          ...platformSavedLinks.value[platformId].links
        };
      }
    };
    
    /**
     * Основная функция для обновления ссылок платформы на основе шаблонов и названия товара.
     * При необходимости восстанавливает оригинальные ссылки.
     * @param title - Название товара для генерации новых ссылок.
     */
    const changePlatformsLinks = (title: string) => {
      if (isSearchByTitle.value) {
        saveOriginalLinks(); // Сохраняем оригинальные ссылки для текущего товара
    
        // Обновляем ссылки для каждой платформы
        props.itemStore.platforms.forEach((platform: Platform) => {
          const newLink = generateSearchLink(platform, title);
          updateLinkIfDifferent(platform.id, newLink); // Проверяем и обновляем ссылку, если нужно
        });
        return;
      }
      // Восстанавливаем оригинальные ссылки, если byTitle отключен
      restorePlatformsLinks();
    };

    
    /**
     * Наблюдает за изменением заголовка (title) и обновляет ссылки при включенном byTitle.
     * Автоматически удаляет начальные пробелы, если они есть.
     * @param newTitle - новое название товара.
     */
    watch(() => props.localItemData.title, (newTitle) => {
      // Убираем пробелы в начале строки
      const trimmedTitle = newTitle?.replace(/^\s+/, "") || "";
    
      // Если измененный title отличается, обновляем его, удаляя начальные пробелы
      if (trimmedTitle !== newTitle) {
        props.localItemData.title = trimmedTitle;
      }
    
      // Обновляем ссылки, если byTitle включен и title не пустой
      if (isSearchByTitle.value) {
        if (!isEmpty(trimmedTitle)) {
          changePlatformsLinks(trimmedTitle);
        } else {
          // Восстанавливаем оригинальные ссылки
          restorePlatformsLinks();
        }
      }
    });

    /**
     * Наблюдает за изменением структуры ссылок платформы и применяет очистку данных.
     * @param newLinks - новый объект с данными платформы.
     */
    watch(() => props.localItemData.platform, (newLinks) => {
      if (newLinks && typeof newLinks === 'object') {
        Object.keys(newLinks).forEach(platformID => {
          const platformData = newLinks[platformID];
          if (platformData?.links) {
            Object.entries(platformData.links).forEach(([linkID, link]) => {
              if (link) props.localItemData.platform[platformID].links[linkID] = cleanLinkInput(link);
            });
          }
        });
      }
    }, { deep: true });

    return {
      userStore,
      isLinkValid,
      addLink,
      removeLink,
      clickLink,
      translatedLink,
      toggleByTitle,
      isEmpty,
      isSearchByTitle,
      isChackByTitle,
    };
  },
});
</script>
