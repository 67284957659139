export interface Platform {
  id: number | null;
  title: string;
  code: string;
  url: string;
  template_search_link: string;
  template_sku_link: string;
}

export interface ItemData {
  id: number | null;
  title?: string;
  required_words?: string;
  platform?: Record<string, PlatformData>;
  wishprice: number | null;
  group: string;
  status: string;
  ai_status: string;
  ai_score: string;
  image_load: string;
  image: string | null;
  search_by_title: string;
}

export interface PlatformData {
  links?: Record<string, string>;
  count?: Record<string, number>;
  links_date_update?: Record<string, string>;
}

export interface GroupData {
  title?: string;
  descr: string;
  status: string;
  image_load: string;
  image: string | null;
}

export interface IsFocused {
  title: boolean;
  required_words: boolean;
  excluded_words: boolean;
  wishprice: boolean;
  platform: Record<string, boolean>;
  group: boolean;
}

/**
 * Инициализирует данные товара с пустыми или дефолтными значениями для каждого поля.
 * 
 * @param platforms - массив платформ, для которых необходимо инициализировать данные.
 * @returns Объект с данными товара.
 */
export function initItemData(platforms: Platform[]): ItemData {
  return {
    id: null,
    title: undefined,
    wishprice: undefined,
    group: "0",
    image_load: "1",
    image: null,
    status: "1",
    platform: initPlatformLinks(platforms),
    required_words: "",
    excluded_words: "",
    ai_status: "1",
    ai_score: "0.85",
    search_by_title: "0"
  };
}

/**
 * Возвращает объект шаблона для данных группы с пустыми или дефолтными значениями для каждого поля.
 * 
 * @returns Объект с данными группы, соответствующий интерфейсу GroupTemplate.
 */
export function initGroupData(): GroupData {
  return {
    title: undefined,
    descr: "",
    status: "1",
    image_load: "1",
    image: null,
  };
}

/**
 * Инициализирует данные по платформам, создавая или обновляя объект ссылок для каждой платформы.
 * Если ключи начинаются с 'n', они удаляются, и добавляется пустая ссылка с ключом 'n0', если других ссылок нет.
 * 
 * @param platforms - массив платформ, для которых необходимо инициализировать данные.
 * @param existingPlatformData - существующие данные по платформам, если таковые имеются.
 * @returns Объект, содержащий ссылки для каждой платформы.
 */
export function initPlatformLinks(platforms: Platform[], existingPlatformData?: Record<string, { links?: Record<string, string> }>): Record<string, { links: Record<string, string> }> {
  const platformLinks: Record<string, { links: Record<string, string> }> = existingPlatformData || {};

  platforms.forEach(platform => {
    if (!platformLinks[platform.id]) {
      platformLinks[platform.id] = { links: {} };
    }

    const links = platformLinks[platform.id].links;

    Object.keys(links).forEach(key => {
      if (key.includes('n')) {
        delete links[key];
      }
    });

    if (Object.keys(links).length === 0) {
      links['n0'] = '';
    }
  });

  return platformLinks;
}

/**
 * Отключает кнопку отправки, делая её неактивной и добавляя индикатор загрузки.
 * 
 * @param submitButtonRef - ссылка на кнопку отправки, которую необходимо отключить.
 */
export function disableSubmitButton(submitButtonRef: Ref<null | HTMLButtonElement>): void {
  if (submitButtonRef.value) {
    submitButtonRef.value.disabled = true;
    submitButtonRef.value.setAttribute("data-kt-indicator", "on");
  }
}

/**
 * Включает кнопку отправки, делая её активной и убирая индикатор загрузки.
 * 
 * @param submitButtonRef - ссылка на кнопку отправки, которую необходимо включить.
 */
export function enableSubmitButton(submitButtonRef: Ref<null | HTMLButtonElement>): void {
  if (submitButtonRef.value) {
    submitButtonRef.value.disabled = false;
    submitButtonRef.value.removeAttribute("data-kt-indicator");
  }
}

/**
 * Очищает строку от нежелательных символов, таких как переносы строк и табуляции.
 * 
 * @param str - строка, которую нужно очистить.
 * @returns Очищенная строка.
 */
export function cleanString(str: string): string {
  return typeof str === 'string' ? str.replace(/[\n\t\r]/g, '').trim() : str;
}

/**
 * Преобразует массив объектов с полем `value` в строку значений, разделенных запятыми.
 * Исключает пустые значения и объединяет оставшиеся через запятую.
 *
 * @param data - массив объектов или строка JSON, содержащая массив объектов с полем `value`,
 *               или строка, уже представляющая значения через запятую.
 * @returns Строка значений из `value`, разделенных запятой, без пустых значений.
 */
export function valuesToString(data: any): string {
  // Проверка, является ли `data` строкой JSON, и попытка преобразовать его в массив
  if (typeof data === 'string') {
    try {
      data = JSON.parse(data);
    } catch (e) {
      // Если `data` не является JSON, возвращаем его как есть после удаления пустых значений
      return data
        .split(',')            // Разделение строки на элементы массива по запятой
        .map(str => str.trim()) // Удаление лишних пробелов
        .filter(value => value) // Исключаем пустые или falsy значения
        .join(', ');            // Соединяем через запятую
    }
  }

  // Если `data` массив, то обрабатываем его как обычный массив объектов
  if (Array.isArray(data)) {
    return data
      .map(item => item.value) // Извлекаем значение поля "value"
      .filter(value => value)  // Исключаем пустые или falsy значения
      .join(', ');             // Соединяем через запятую
  } else {
    return ''; // Возвращаем пустую строку, если `data` не массив или строка
  }
}

/**
 * Генерирует корректную ссылку для указанной платформы и идентификатора товара.
 * Если `url` — это SKU (число), подставляет его в шаблон SKU-ссылки платформы.
 * Если `url` уже является полной ссылкой, возвращает его без изменений.
 * 
 * @param platform - Платформа, для которой необходимо сгенерировать ссылку.
 * @param url - Идентификатор товара (SKU) или полный URL.
 * @returns {string} - Сгенерированный URL.
 */
export function generateLink(platform: Platform, url: string): string {
  // Проверка, является ли значение SKU (числом)
  if (/^\d+$/.test(url)) {
    return `${platform.url}${platform.template_sku_link.replace("{sku}", url)}`;
  }
  // Если `url` не SKU, предполагается, что это уже готовый URL
  return url;
}

/**
 * Генерирует ссылку для поиска на платформе, подставляя `searchText` в шаблон {search_text}.
 * Кодирует `searchText` для корректного использования в URL.
 * 
 * @param platform - Платформа, на которой выполняется поиск.
 * @param searchText - Название товара, которое будет URL-кодировано и вставлено в шаблон.
 * @returns {string} - Ссылка на поисковую выдачу с подставленным `searchText`.
 */
export function generateSearchLink(platform: Platform, searchText: string): string {
  const encodedText = encodeURIComponent(searchText); // Кодирование текста для URL
  const searchLink = platform.template_search_link.replace("{search_text}", encodedText);
  return `${platform.url}${searchLink}`;
}

/**
 * Проверяет, соответствует ли указанный URL домену платформы.
 * 
 * @param url - URL для проверки.
 * @param platform - платформа, с которой сравнивается URL.
 * @returns true, если URL соответствует домену платформы, иначе false.
 */
export function isUrlMatchingPlatform(url: string, platform: Platform): boolean {
  if (!platform) return false;

  // Регулярное выражение для проверки домена платформы
  const domain = platform.url.replace(/^(https?:\/\/)?(www\.)?/, '');
  const urlPattern = new RegExp(`^(https?:\\/\\/)?(www\\.)?${domain}(/.*)?$`, 'i');
  return urlPattern.test(url);
}

/**
 * Проверяет, является ли переданное значение числом или корректным URL для указанной платформы.
 * 
 * @param value - Значение для проверки, может быть числом или URL.
 * @param platform - Объект платформы, с которой нужно сравнить URL.
 * @returns {boolean} - true, если значение является числом или соответствует URL платформы, иначе false.
 */
export function validateUrlOrInteger(value: string, platform: any): boolean {
  if (!value) return true; // Пустое значение считается валидным
  if (/^[0-9]+$/.test(value)) return true; // Если значение состоит только из чисел, оно валидно
  return isUrlMatchingPlatform(value, platform); // Проверка, соответствует ли URL платформе
}

/**
 * Очищает входное значение для ссылки: возвращает полный URL, если он есть, или число (SKU), если это идентификатор.
 * 
 * @param value - значение для очистки.
 * @returns Очищенное значение, либо пустая строка, если значение некорректно.
 */
export function cleanLinkInput(value: string | undefined | null): string {
  if (typeof value !== 'string') {
    return ''; // Возвращаем пустую строку, если value не является строкой
  }
  const urlMatch = value.match(/https?:\/\/[^\s]+/);
  if (urlMatch) {
    return urlMatch[0]; // Если это URL, возвращаем его
  }
  // Если это не URL, но это число, возвращаем его
  if (/^\d+$/.test(value)) {
    return value;
  }
  return ''; // В остальных случаях возвращаем пустую строку
}
