<template>
  <div
    class="modal fade"
    id="modal_items"
    tabindex="-1"
    ref="modalRef"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div :class="[
      'modal-dialog modal-dialog-centered',
      isMobile ? 'modal-fullscreen' : 'modal-lg'
    ]">
      <div class="modal-content">
        <div class="modal-header flex-stack border-bottom-0 px-lg-10 pt-lg-10 pb-0">
          <h2>{{ modalTitle }}</h2>
          <div 
            class="btn btn-sm btn-icon btn-light btn-active-color-primary" 
            data-bs-dismiss="modal"
          >
            <KTIcon icon-name="cross" icon-class="fs-1" />
          </div>
        </div>
        <div class="modal-body p-lg-10 pt-lg-3">
          <ItemsWizardStepper
            :edit-mode="editMode"
            :item-data="itemData"
            :step-page="stepPage"
            @data-updated="handleUpdated"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from "vue";
import { hideModal } from "@/core/helpers/modal";
import { useI18n } from "vue-i18n";
import { isMobile } from "@/core/helpers/mobile";

import ItemsWizardStepper from "@/components/form/items/ItemsWizardStepper.vue";

export default defineComponent({
  name: "create-items-modal",
  components: {
    ItemsWizardStepper,
  },
  props: {
    editMode: {
      type: Boolean,
      required: false,
      default: false
    },
    itemData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    stepPage: {
      type: String,
      required: false,
      default: null
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const modalRef = ref<HTMLElement | null>(null);

    const modalTitle = computed(() => props.editMode ? t('pages.items.modal.title.edit') : t('pages.items.modal.title.new'));
    
    const handleUpdated = () => {
      hideModal(modalRef.value);
      emit("data-updated");
    };
    
    // Обработчик события скрытия модального окна
    const handleModalHidden = () => {
      const formEl = document.getElementById('kt_modal_items_form');
      formEl?.reset();
    };
    
    onMounted(() => {
      modalRef.value?.addEventListener('hidden.bs.modal', handleModalHidden);
    });
    
    onBeforeUnmount(() => {
      modalRef.value?.removeEventListener('hidden.bs.modal', handleModalHidden);
    });
    
    return {
      isMobile,
      modalRef,
      modalTitle,
      handleUpdated,
    };
  },
});
</script>
