<template>
  <div class="card card-flush">
    <div class="card-header flex-nowrap py-5 gap-0 gap-md-5">
      <div class="card-title">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bold fs-3 mb-1">
            {{ $t('pages.items.titleCard') }}
          </span>
          <span class="text-muted mt-1 fw-semibold fs-7">
            {{ $t('pages.items.formatter.total') }} 
            <SmoothCounter :targetValue="filteredCount" />
            <EndingFormatter
              :number="filteredCount"
              :single="$t('pages.items.formatter.single')"
              :few="$t('pages.items.formatter.few')"
              :many="$t('pages.items.formatter.many')"
            />
          </span>
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light-primary me-3 ps-3 pe-2 px-sm-4 position-relative"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <KTIcon icon-name="filter" icon-class="fs-3" />
          <div class="d-none d-sm-inline-flex">
            {{ $t('pages.items.filterItem') }}
          </div>
          
          <span
            v-if="countFilter.value > 0"
            class="position-absolute top-0 start-0 translate-middle badge badge-circle badge-light fs-9"
          >{{ countFilter }}</span>
          
        </button>
        
        <FilterItems
          :platforms="itemStore.platforms"
          :groups="filteredGroups"
          :filters="filters"
          @applyFilters="applyFilters"
          @resetFilters="resetFilters"
        />

        <ActionsCSV />

        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#modal_items"
          class="btn btn-sm btn-primary"
          :disabled="isAddSearchButtonDisabled"
          @click="openAddItemModal"
        >
          <KTIcon icon-name="plus" icon-class="fs-3" />
            {{ $t('pages.items.addItem') }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0 pb-5">

      <template v-if="isMobile">
        <template v-if="itemStore.searchs.length > 0">
          <div
            v-for="(item, index) in sortedFilteredItems"
            :key="item.id"
            :class="['position-relative pt-5 pb-8', { 'border-bottom': index !== sortedFilteredItems.length - 1 }]"
          >
              <ItemCard
                :item="item"
                :group="itemStore.groups"
              />
            <div class="position-absolute top-0 end-0 mt-5">
              <ActionsSearch
                :data-store="item"
                :only-search="true"
                :is-setting-disabled="isSettingDisabled"
                @data-modal="openEditItemModal"
                @data-archived="fetchSearchData"
                @data-deleted="handleItemDeleted"
              />
            </div>

            <div
              v-if="item.platform"
              class="card card-flush w-100 mt-7 py-5 px-7"
            >
              <div class="row border-bottom border-gray-200 pb-4 fs-9 text-uppercase text-gray-700">
                <div class="col">{{ $t('pages.items.titleTable.platform') }}</div>
                <div class="col text-center">{{ $t('pages.items.titleTable.items') }}</div>
                <div class="col text-end">{{ $t('pages.items.titleTable.updated') }}</div>
              </div>

              <div
                v-for="(platform, platformId) in item.platform"
                :key="platformId"
                class="row mt-2"
              >
                <PlatformInfo class="col" :platform="platform" :platformId="platformId" :getPlatformTitle="getPlatformTitle" />
                <PlatformCount class="col text-center" :platform="platform" />
                <PlatformUpdateDate class="col text-end" :platform="platform" :itemStatus="item.status" />
              </div>
            </div>
          </div>
        </template>
        
        <PlaceholderMobile v-else :length="3" />
      </template>
      
      <template v-else>
        <Datatable
          v-if="itemStore.searchs.length > 0/*  && sortedFilteredItems.length === filteredCount */"
          class="table align-middle table-row-dashed gy-3"
          :header="tableHeader"
          :data="sortedFilteredItems"
          :items-per-page="itemsPerPage"
          :items-per-page-dropdown-enabled="itemsPerPageDropdown"
          :preload-count="10"
          :emptyTableText="$t('dataTable.emptyTable')"
          @on-sort="handleSort"
          @on-items-per-page-change="handleItemsPerPage"
        >
          <template v-slot:title="{ row: item }">
            <ItemCard
              :item="item"
              :group="itemStore.groups"
            />
          </template>
          
          <template v-slot:price="{ row: item }">
            <BestOffer :item="item" />
          </template>
          
          <template v-slot:chart="{ row: item }">
            <SearchChart
              :chart-data="item.chart"
            />
          </template>
  
          <template v-slot:platform="{ row: item }">
            <PlatformInfo
              v-if="item.platform"
              v-for="(platform, platformId) in filteredPlatforms(item.platform)"
              :key="platformId"
              :platform="platform"
              :platformId="platformId"
              :getPlatformTitle="getPlatformTitle"
              class="d-flex align-items-center justify-content-end"
            />
            <div v-else />
          </template>
          
          <template v-slot:count="{ row: item }">
            <PlatformCount
              v-if="item.platform"
              v-for="(platform, platformId) in filteredPlatforms(item.platform)"
              :key="platformId"
              :platform="platform"
              class="d-flex align-items-center justify-content-center"
            />
            <div v-else />
          </template>
          
          <template v-slot:updated="{ row: item }">
            <PlatformUpdateDate
              v-if="item.platform"
              v-for="(platform, platformId) in filteredPlatforms(item.platform)"
              :key="platformId"
              :platform="platform"
              :itemStatus="item.status"
              class="d-flex align-items-center"
            />
            <div v-else />
          </template>
          
          <template v-slot:status="{ row: item }">
            <StatusBadge
              :status="item.status"
              :archive="item.archive"
              :links="item.isLinks"
            />
          </template>
  
          <template v-slot:actions="{ row: item }">
            <ActionsSearch
              :data-store="item"
              :only-search="true"
              :is-setting-disabled="isSettingDisabled"
              @data-modal="openEditItemModal"
              @data-archived="fetchSearchData"
              @data-deleted="handleItemDeleted"
            />
          </template>
        </Datatable>
        
        <PlaceholderDesktop
          v-else
          :length="5"
          :table-header="tableHeader"
          :items-per-page="itemsPerPage"
        />
      </template>
    </div>
  </div>

  <ItemsModal 
    :edit-mode="editMode" 
    :item-data="itemData"
    :step-page="stepPage"
    @data-updated="fetchSearchData" 
  />
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useConfigStore } from "@/stores/config";
import { useAuthStore } from "@/stores/auth";
import { useItemStore } from "@/stores/item";
import { isMobile } from "@/core/helpers/mobile";
import cloneDeep from 'lodash/cloneDeep';

import arraySort from 'array-sort';
import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import ItemsModal from "@/components/modals/wizards/ItemsModal.vue";
import FilterItems, { initialFilters } from "@/components/dropdown/FilterItems.vue";
import ItemCard from "@/components/items/ItemCard.vue";
import BestOffer from "@/components/items/BestOffer.vue";
import PlatformInfo from "@/components/items/PlatformInfo.vue";
import PlatformCount from "@/components/items/PlatformCount.vue";
import PlatformUpdateDate from "@/components/items/PlatformUpdateDate.vue";
import StatusBadge from "@/components/items/StatusBadge.vue";
import EndingFormatter from "@/components/formatters/EndingFormatter.vue";
import SearchChart from "@/components/chart/SearchChart.vue";
import PlaceholderDesktop from '@/components/placeholder/PagesSearchDesktop.vue';
import PlaceholderMobile from '@/components/placeholder/PagesSearchMobile.vue';
import ActionsSearch from "@/components/dropdown/ActionsSearch.vue";
import ActionsCSV from "@/components/dropdown/ActionsCSV.vue";
import SmoothCounter from '@/components/formatters/SmoothCounter.vue'

export default defineComponent({
  name: "items",
  components: {
    Datatable,
    ItemsModal,
    FilterItems,
    ItemCard,
    BestOffer,
    PlatformInfo,
    PlatformCount,
    PlatformUpdateDate,
    StatusBadge,
    EndingFormatter,
    SearchChart,
    PlaceholderDesktop,
    PlaceholderMobile,
    ActionsSearch,
    ActionsCSV,
    SmoothCounter,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const bodyStore = useConfigStore();
    const userStore = useAuthStore();
    const itemStore = useItemStore();
    const editMode = ref(false);
    const itemData = ref<Record<string, any>>({});
    const itemsPerPage = ref(bodyStore.getLayoutConfig('datatable.itemsPerPage'));
    const isSettingDisabled = ref(true);
    const stepPage = ref<string | null>(null);
    const countFilter = ref(0);
    
    const filteredCount = computed(() => {
      // Проверка, что все фильтры пусты
      const noFiltersApplied = 
        filters.value.groups === "0" &&
        filters.value.platforms.length === 0 &&
        !filters.value.status.any &&
        filters.value.status.active &&
        !filters.value.status.archive &&
        !filters.value.priceComparison;
      
      // Если фильтры пустые, возвращаем общее кол-во поисков
      if (noFiltersApplied) {
        return itemStore.countSearch;
      }
      
      return filteredItems.value.length;
    });
    
    const filteredGroups = computed(() => itemStore.groups.filter(group => group.status === '1'));

    const itemsPerPageDropdown = computed(() => {
      return itemStore.searchs?.length >= 10;
    });

    const isAddSearchButtonDisabled = computed(() => 
      userStore.isSubscription('search', itemStore.searchs?.length)
    );

    const tableHeader = ref([
      {
        columnName: t('pages.items.titleTable.search'),
        columnLabel: "title",
        sortEnabled: true,
        columnClass: 'min-w-375px'
      },
      {
        columnName: t('pages.items.titleTable.price'),
        columnLabel: "price",
        sortEnabled: true,
        columnClass: 'text-end min-w-125px pe-3'
      },
      {
        // columnName: t('pages.items.titleTable.chart'),
        columnLabel: "chart",
        sortEnabled: false,
        columnClass: 'text-center min-w-100px pe-3'
      },
      {
        columnName: t('pages.items.titleTable.platform'),
        columnLabel: "platform",
        sortEnabled: false,
        columnClass: 'text-end min-w-100px pe-7'
      },
      {
        columnName: t('pages.items.titleTable.items'),
        columnLabel: "count",
        sortEnabled: false,
        columnClass: 'min-w-90px text-center pe-7'
      },
      {
        columnName: t('pages.items.titleTable.updated'),
        columnLabel: "updated",
        sortEnabled: false,
        columnClass: 'min-w-125px'
      },
      {
        columnName: t('pages.items.titleTable.status'),
        columnLabel: "status",
        sortEnabled: true,
        columnClass: 'text-center min-w-125px pe-7'
      },
      {
        // columnName: t('pages.items.titleTable.action'),
        columnLabel: "actions",
        sortEnabled: false,
        columnClass: 'min-w-50px'
      },
    ]);
    
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    
    // Функция для получения названия платформы по её ID
    const getPlatformTitle = (platformId) => {
      const platform = itemStore.platforms.find(p => p.id === platformId);
      return platform ? capitalizeFirstLetter(platform.code) : '';
    };
    
    // Функция для поиска минимальной даты в объекте
    const getEarliestDate = (dates: Record<string, string>) => {
      if (!dates || Object.keys(dates).length === 0) return '';
      
      const dateArray = Object.values(dates).map(date => new Date(date));
      const earliestDate = new Date(Math.min(...dateArray));
      
      return earliestDate.toISOString(); // Преобразуем обратно в строку ISO
    };
        
    const fetchSearchData = async () => {
      isSettingDisabled.value = true;
      await Promise.all([
        itemStore.fetchGroupData(),
        itemStore.fetchSearchData()
      ]);
      isSettingDisabled.value = false;
    };
    
    const sortState = ref({ label: null, order: 'asc' });
    
    const handleSort = (sort) => {
      sortState.value = sort;
    };
    
    const sortedFilteredItems = computed(() => {
      const items = filteredItems.value.slice(); // копируем массив для сортировки
    
      if (sortState.value.label === 'price') {
        // сортируем по best_offer.min_price, конвертируя в число для корректного сравнения
        return items.sort((a, b) => {
          const priceA = parseFloat(a.best_offer?.min_price || '0');
          const priceB = parseFloat(b.best_offer?.min_price || '0');
          return sortState.value.order === 'desc' ? priceB - priceA : priceA - priceB;
        });
      }
    
      if (sortState.value.label) {
        // стандартная сортировка по другим параметрам
        return arraySort(items, sortState.value.label, {
          reverse: sortState.value.order === 'desc',
        });
      }
    
      return items; // если сортировка не указана
    });

    // Извлечение параметра из query
    const initialGroup = route.query.group ? String(route.query.group) : "0";
    
    // Создание глубокой копии объекта initialFilters
    const filters = ref({ 
      ...JSON.parse(JSON.stringify(initialFilters)),
      groups: initialGroup 
    });
    
    const applyFilters = (appliedFilters) => {
      filters.value = appliedFilters;
    };
    
    const resetFilters = () => {
      // Создаем глубокую копию initialFilters при сбросе
      filters.value = JSON.parse(JSON.stringify(initialFilters));
      applyFilters(filters.value);
    };
    
    const filteredItems = computed(() => {
      return itemStore.searchs.filter(item => {
        const groupMatch = filters.value.groups === "0" || item.group == filters.value.groups;
        const platformMatch = filters.value.platforms.length === 0 || (item.platform && Object.keys(item.platform).some(platformId => filters.value.platforms.includes(platformId)));
        const statusMatch = filters.value.status.any || item.status == (filters.value.status.active ? '1' : '0') && item.archive == (filters.value.status.archive ? '1' : '0');
        const priceComparisonMatch = !filters.value.priceComparison || itemStore.priceComparison(item);
        return groupMatch && platformMatch && statusMatch && priceComparisonMatch;
      });
    });
    
    // Подсчет активных фильтров
    const activeFiltersCount = computed(() => {
      let count = 0;
    
      // Проверка, выбрана ли группа (группа, отличная от "0", считается активным фильтром)
      if (filters.value.groups !== "0") {
        count++;
      }
    
      // Проверка количества выбранных платформ
      count += filters.value.platforms.length;
    
      // Проверка статусов (если активен архив)
      if (filters.value.status.archive) {
        count++;
      }
    
      // Проверка статусов (если активен любой)
      if (filters.value.status.any) {
        count++;
      }
      
      // Проверка, активирован ли фильтр по цене
      if (filters.value.priceComparison) {
        count++;
      }
      
      return count;
    });
    
    watch(filters, (newFilters) => {
      applyFilters(newFilters);
      countFilter.value = activeFiltersCount;
    }, { deep: true });

    onMounted(() => {
      fetchSearchData();
      countFilter.value = activeFiltersCount;
    });
    
    // Наблюдение за изменениями параметра group в query
    watch(() => route.query.group, (newGroup, oldGroup) => {
      if (newGroup !== oldGroup) {
        filters.value.groups = newGroup ? String(newGroup) : "0";
      }
    }, { immediate: true });

    const openAddItemModal = () => {
      editMode.value = false;
      itemData.value = {};
      stepPage.value = null;
    };
    
    const openEditItemModal = (data) => {
      editMode.value = true;
      itemData.value = cloneDeep(data.item);
      stepPage.value = data.step;
    };

    const handleItemDeleted = (itemId: number) => {
      const index = itemStore.searchs.findIndex(item => item.id === itemId);
    
      // Проверяем, что элемент найден
      if (index !== -1) {
        itemStore.searchs.splice(index, 1);
        
        // Безопасно уменьшаем счётчик, если он больше 0
        itemStore.countSearch = Math.max(0, itemStore.countSearch - 1);
      }
    };
    
    const handleItemsPerPage = (val) => {
      bodyStore.setLayoutConfigProperty("datatable.itemsPerPage", val);
    };
    
    /**
     * Фильтрует платформы для вывода в зависимости от значений в filters.value.platforms.
     * @param platforms - Объект с платформами для фильтрации.
     * @returns {Object} - Отфильтрованный объект платформ.
     */
    const filteredPlatforms = (platforms: Record<string, any>) => {
      // Если filters пуст, возвращаем все платформы
      if (!filters.value.platforms.length) {
        return platforms;
      }
      
      // Возвращаем только те платформы, которые соответствуют filters.value.platforms
      return Object.fromEntries(
        Object.entries(platforms).filter(([platformId]) => 
          filters.value.platforms.includes(platformId)
        )
      );
    };
        
    return {
      isMobile,
      tableHeader,
      itemStore,
      fetchSearchData,
      openAddItemModal,
      openEditItemModal,
      editMode,
      itemData,
      stepPage,
      getPlatformTitle,
      filters,
      applyFilters,
      resetFilters,
      filteredGroups,
      filteredPlatforms,
      sortedFilteredItems,
      filteredCount,
      handleSort,
      handleItemsPerPage,
      itemsPerPage,
      itemsPerPageDropdown,
      handleItemDeleted,
      isAddSearchButtonDisabled,
      isSettingDisabled,
      getEarliestDate,
      countFilter,
    };
  },
});
</script>