<template>
  <!--begin::Stepper-->
  <div
    class="stepper stepper-links d-flex flex-column"
    ref="itemsStepperRef"
  >
    <!--begin::Nav-->
    <div class="stepper-nav ms-0">
      <!--begin::Step 1-->
      <div
        :class="{
          'stepper-item ms-0 d-flex flex-row': true,
          'current': !editMode || stepGoPage === 'main',
          'cursor-pointer': editMode
        }"
        data-kt-stepper-element="nav"
        @click="editMode && stepGoPage !== 'main' && handleClick('main')"
      >
        <KTIcon
          icon-name="file-sheet"
          icon-class="fs-1 me-2"
          :class="{'stepper-title': !editMode || stepGoPage === 'main'}"
        />
        <div
          :class="{
            'stepper-title fs-6': true,
            'text-gray-400': editMode && stepGoPage !== 'main'
          }">
          {{ $t('pages.items.modal.stepper.main') }}
        </div>
      </div>
      <!--end::Step 1-->

      <!--begin::Step 2-->
      <div
        :class="{
          'stepper-item ms-4 d-flex flex-row': true,
          'current': editMode && stepGoPage === 'links',
          'cursor-pointer': editMode
        }"
        data-kt-stepper-element="nav"
        @click="editMode && stepGoPage !== 'links' && handleClick('links')"
      >
        <KTIcon
          icon-name="mouse-square"
          icon-class="fs-1 me-2"
          :class="{'stepper-title': !editMode || stepGoPage === 'links'}"
        />
        <div
          :class="{
            'stepper-title fs-6': true,
            'text-gray-400': editMode && stepGoPage !== 'links'
          }">
          {{ $t('pages.items.modal.stepper.links') }}
        </div>
      </div>
      <!--end::Step 2-->

      <!--begin::Step 3-->
      <div
        :class="{
          'stepper-item ms-4 me-0 d-flex flex-row': true,
          'current': editMode && stepGoPage === 'filters',
          'cursor-pointer': editMode
        }"
        data-kt-stepper-element="nav"
        @click="editMode && stepGoPage !== 'filters' && handleClick('filters')"
      >
        <KTIcon
          icon-name="filter"
          icon-class="fs-1 me-2"
          :class="{'stepper-title': !editMode || stepGoPage === 'filters'}"
        />
        <div
          :class="{
            'stepper-title fs-6': true,
            'text-gray-400': editMode && stepGoPage !== 'filters'
          }">
          {{ $t('pages.items.modal.stepper.filters') }}
        </div>
      </div>
      <!--end::Step 3-->
    </div>
    <!--end::Nav-->

    <ItemsWizardForm
      :stepper-el="itemsStepperRef"
      :edit-mode="editMode"
      :item-data="itemData"
      :step-page="stepGoPage"
      @data-updated="handleUpdated"
      @step-reset="stepReset"
    />
  </div>
  <!--end::Stepper-->
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import ItemsWizardForm from "@/components/form/items/ItemsWizardForm.vue";

export default defineComponent({
  name: "create-items-wizard",
  components: {
    ItemsWizardForm,
  },
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    itemData: {
      type: Object,
      required: true
    },
    stepPage: {
      type: String,
      required: false
    },
  },
  setup(props, { emit }) {
    const itemsStepperRef = ref<HTMLElement | null>(null);
    const stepPageLink = ref<string | null>(null);

    const stepGoPage = computed(() => stepPageLink.value ?? props.stepPage);

    const handleClick = (step: string) => {
      stepPageLink.value = step;
    };
    
    const stepReset = () => {
      stepPageLink.value = null;
    };

    const handleUpdated = () => {
      emit("data-updated");
    };
    
    return {
        itemsStepperRef,
        handleUpdated,
        handleClick,
        stepGoPage,
        stepReset,
    };
  },
});
</script>