<template>
  <!--begin::Form-->
  <form
    class="form mt-8"
    novalidate
    id="kt_modal_items_form"
    @reset="onReset"
    @submit="handleStep"
  >
    <ItemStep1
      :local-item-data="localItemData"
      :item-store="itemStore"
      :on-reset="onReset"
      @file-change="handleFileChange"
    />
    
    <ItemStep2
      :local-item-data="localItemData"
      :item-store="itemStore"
    />
    
    <ItemStep3
      :local-item-data="localItemData"
      :on-reset="onReset"
    />

    <!--begin::Actions-->
    <div class="d-flex flex-stack pt-10">
      <!--begin::Wrapper-->
      <div class="me-2">
        <button
          v-if="!editMode"
          type="button"
          class="btn btn-light-primary me-3"
          data-kt-stepper-action="previous"
          @click="previousStep"
        >
          <KTIcon icon-name="arrow-left" icon-class="fs-4 me-1" />
          {{ $t('buttons.back') }}
        </button>
        
        <button
          v-else
          type="button"
          class="btn btn-light me-3"
          data-bs-dismiss="modal"
        >
          {{ $t('buttons.cancel') }}
        </button>
      </div>
      <!--end::Wrapper-->
    
      <!--begin::Wrapper-->
      <div>
        <button
          v-if="editMode || currentStepIndex === totalSteps - 1"
          type="button"
          class="btn btn-primary"
          @click="onSubmit"
          ref="submitButtonRef"
        >
          <span class="indicator-label">
            {{ editMode ? $t('buttons.save') : $t('buttons.submit') }}
          </span>
          <span class="text-nowrap indicator-progress">
            {{ $t('buttons.wait') }}
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
    
        <button v-else type="submit" class="btn btn-primary">
          {{ $t('buttons.continue') }}
          <KTIcon icon-name="arrow-right" icon-class="fs-4 ms-2 me-0" />
        </button>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Actions-->

  </form>
  <!--end::Form-->
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted, nextTick } from "vue";
import { useForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useItemStore } from "@/stores/item";
import { StepperComponent } from "@/assets/ts/components";
import { addToast } from '@/utils/toastManager';
import { 
  initItemData, 
  disableSubmitButton,
  enableSubmitButton,
  cleanString,
  valuesToString,
  initPlatformLinks,
  validateUrlOrInteger, 
  type ItemData,
} from "@/utils/helpers/itemHelpers";

import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";

import ItemStep1 from "@/components/form/items/steps/ItemStep1.vue";
import ItemStep2 from "@/components/form/items/steps/ItemStep2.vue";
import ItemStep3 from "@/components/form/items/steps/ItemStep3.vue";

export default defineComponent({
  name: "item-modal",
  components: {
    ItemStep1,
    ItemStep2,
    ItemStep3,
  },
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    itemData: {
      type: Object,
      required: true
    },
    stepperEl: {
      type: Object,
      required: false
    },
    stepPage: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const itemStore = useItemStore();
    const currentStepIndex = ref(0);
    const submitButtonRef = ref<HTMLButtonElement | null>(null);
    const localItemData = ref<ItemData>({ ...props.itemData });
    const stepperObj = ref<StepperComponent | null>(null);
    const selectedFile = ref<File | null>(null);

    // Функция для создания схемы валидации для конкретной платформы
    const createPlatformValidationSchema = (platformID: string) => {
      const platform = itemStore.platforms?.find(p => p.id === platformID);
    
      // Если нет данных для платформы, возвращаем пустую схему
      if (!localItemData.value.platform || !localItemData.value.platform[platformID]) {
        return Yup.object().shape({});
      }
    
      // Создаем схему валидации для каждой ссылки
      return Yup.object().shape(
        Object.keys(localItemData.value.platform[platformID].links || {}).reduce((linkAcc, linkKey) => {
          linkAcc[`key_${linkKey}`] = Yup.mixed().test(
            'url-or-integer',
            t('validation.urlOrSKU'),
            value => validateUrlOrInteger(value, platform)
          );
          return linkAcc;
        }, {} as Record<string, Yup.StringSchema>)
      );
    };

    // Схема валидации для каждого шага
    const createAccountSchema = computed(() => [
      // Шаг 1: Валидация заголовка
      Yup.object({
        title: Yup.string().trim() // Убирает пробелы в начале и конце строки
          .required(t('validation.requiredField', { field: t('modal.label.title') })),
      }),
      // Шаг 2: Валидация платформы
      Yup.object().shape({
        title: Yup.string().trim()
          .required(t('validation.requiredField', { field: t('modal.label.title') })),
        platform: Yup.object().shape(
          Object.keys(localItemData.value.platform || {}).reduce((acc, platformID) => {
            acc[`platform_${platformID}`] = createPlatformValidationSchema(platformID);
            return acc;
          }, {} as Record<string, Yup.ObjectSchema>)
        )
      }),
      // Шаг 3: Дополнительные шаги (если есть)
      Yup.object({
      }),
    ]);

    // Текущая схема валидации для каждого шага
    const currentSchema = computed(() => {
      return createAccountSchema.value[currentStepIndex.value];
    });
    
    // Использование `useForm` с текущей схемой валидации
    const { handleSubmit, resetForm } = useForm({
      validationSchema: currentSchema,
    });

    // Общее количество шагов
    const totalSteps = computed(() => {
      return stepperObj.value ? stepperObj.value.totalStepsNumber : 1;
    });
    
    // Функция для перехода на предыдущий шаг
    const previousStep = () => {
      currentStepIndex.value--;
      stepperObj.value?.goPrev();
    };

    // Функция для обработки текущего шага
    const handleStep = handleSubmit((values) => {
      // Переход к следующему шагу
      currentStepIndex.value++;
      stepperObj.value?.goNext();
    });
    
    const handleFileChange = (file: File) => {
      selectedFile.value = file;
    };

    const onReset = async () => {
      currentStepIndex.value = 0;
      stepperObj.value?.goFirst();
      selectedFile.value = null;
      Object.assign(localItemData.value, initItemData(itemStore.platforms));
      resetForm();
      emit("step-reset");
    };
    
    const onSubmit = async () => {
      try {
        disableSubmitButton(submitButtonRef);
        const formData = createFormData();
        await (props.editMode ? itemStore.editSearch(formData) : itemStore.addSearch(formData));
    
        if (!Object.keys(itemStore.errors).length) {
          handleSuccess();
        } else {
          handleError(itemStore.errors);
        }
      } catch (error) {
        handleError(error);
      } finally {
        enableSubmitButton(submitButtonRef);
      }
    };
    
    // Создание объекта FormData
    const createFormData = () => {
      const formData = new FormData();
      
      const fields = {
        "ID": localItemData.value.id,
        "UF_TITLE": cleanString(localItemData.value.title),
        "UF_REQUIRED_WORDS": valuesToString(localItemData.value.required_words),
        "UF_EXCLUDED_WORDS": valuesToString(localItemData.value.excluded_words),
        "UF_WISHPRICE": cleanString(localItemData.value.wishprice),
        "UF_GROUP_ID": cleanString(localItemData.value.group ?? ''),
        "UF_IMAGE_LOAD": localItemData.value.image_load,
        "UF_STATUS": localItemData.value.status,
        "UF_AI_STATUS": localItemData.value.ai_status,
        "UF_SEARCH_BY_TITLE": localItemData.value.search_by_title,
      };
      
      Object.entries(fields).forEach(([key, value]) => {
        if (value !== undefined) {
          formData.append(key, value);
        }
      });
    
      if (localItemData.value.ai_status === '1') {
        formData.append("UF_AI_SCORE", localItemData.value.ai_score);
      }
      
      if (selectedFile.value) {
        formData.append("FILE_IMAGE", selectedFile.value);
      }
    
      // Добавляем платформенные ссылки
      addPlatformLinksToFormData(formData);
      
      return formData;
    };

    // Константа для добавления ссылок платформ в FormData
    const addPlatformLinksToFormData = (formData: FormData) => {
      Object.entries(localItemData.value.platform).forEach(([platformID, platformData]) => {
        if (platformData?.links) {
          Object.entries(platformData.links).forEach(([linkID, link]) => {
            if (link) {
              formData.append(`links[${platformID}][${linkID}]`, cleanString(link));
            }
          });
        }
      });
    };
    
    // Константа для обработки успешного завершения
    const handleSuccess = () => {
      addToast(
        t('messages.any.success'),
        t(`messages.text.success.${props.editMode ? 'editSearch' : 'addSearch'}`),
        'success'
      );
      emit("data-updated");
      
      setTimeout(() => {
        onReset(); // Сбрасываем данные с задержкой
      }, 300);
    };
    
    // Константа для обработки ошибок
    const handleError = (error) => {
      Swal.fire({
        text: error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then(() => {
        itemStore.errors = [];
      });
    };
    
    const initLinks = () => {
      localItemData.value.platform = initPlatformLinks(itemStore.platforms, localItemData.value.platform);
    };
    
    const updateStep = () => {
      if (props.stepPage === 'links') {
        currentStepIndex.value = 1;
        stepperObj.value?.goto(2);
      } else if (props.stepPage === 'filters') {
        currentStepIndex.value = 2;
        stepperObj.value?.goto(3);
      } else {
        currentStepIndex.value = 0;
        stepperObj.value?.goto(1);
      }
    };
    
    onMounted(async () => {
      // Проверяем, нужно ли загружать данные платформ
      if (itemStore.platforms.length === 0) {
        await itemStore.fetchPlatformData();
      }

      if (itemStore.platforms?.length > 0) {
        localItemData.value = { ...initItemData(itemStore.platforms) };
        initLinks();
      }
            
      nextTick(() => {
        if (props.stepperEl) {
          stepperObj.value = StepperComponent.createInsance(props.stepperEl);
        }
      });
    });
    
    watch(() => props.itemData, (newData) => {
      if (newData?.title) {
        localItemData.value = { ...newData };
        initLinks();
        updateStep();
      }
    });
    
    watch(() => props.stepPage, (newStep) => {
      updateStep();
    });
        
    watch(() => itemStore.platforms, (newPlatforms) => {
      if (newPlatforms?.length > 0) {
        localItemData.value = { ...initItemData(newPlatforms) };
        initLinks();
      }
    });

    return {
      itemStore,
      localItemData,
      onReset,
      onSubmit,
      submitButtonRef,
      currentStepIndex,
      totalSteps,
      previousStep,
      handleStep,
      handleFileChange,
    };
  },
});
</script>
