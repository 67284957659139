<template>
  <!--begin::Alert drawer-->
  <div
    id="kt_alert"
    class="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="alert"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'300px', 'sm': '400px', 'md': '500px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_alert_toggle"
    data-kt-drawer-close="#kt_alert_close"
  >
    <div class="card shadow-none w-100 border-bottom-0">
      <!--begin::Header-->
      <div class="card-header pb-0 border-bottom-0" id="kt_alert_header">
          <!--begin::Tabs-->
          <ul class="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold flex-fill">
            <li class="nav-item">
              <a
                class="nav-link opacity-75 opacity-state-100 fs-5 active"
                data-bs-toggle="tab"
                href="#kt_topbar_alerts_1"
                >{{ $t('navbar.alert.notifications')}}</a
              >
            </li>

<!--             <li class="nav-item">
              <a
                class="nav-link opacity-75 opacity-state-100 fs-5"
                data-bs-toggle="tab"
                href="#kt_topbar_alerts_2"
                >Alerts</a
              >
            </li> -->
          </ul>
          <!--end::Tabs-->
          
          <div class="card-toolbar">
            <button
              type="button"
              class="btn btn-sm btn-icon btn-active-light-primary me-n5"
              id="kt_alert_close"
              aria-label="close"
            >
              <KTIcon icon-name="cross" icon-class="fs-1" />
            </button>
          </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_alert_body">
        <!--begin::Content-->
        <div
          id="kt_activities_scroll"
          class="position-relative scroll-y tab-content"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_activities_body"
          data-kt-scroll-dependencies="#kt_activities_header"
        >
          <!--begin::Tab panel-->
          <div class="tab-pane fade show active" id="kt_topbar_alerts_1" role="tabpanel">
            <!--begin::Items-->
            <template v-for="(item, index) in sortedItems" :key="index">
              <!-- Разделитель для новых дат -->
              <template v-if="index === 0 || !isSameDate(sortedItems[index - 1].date, item.date)">
                <DateFormatter 
                  :date="item.date"
                  :format="'X'"
                  class="text-nowrap text-muted fw-semibold fs-7"
                />
                <div class="separator mb-4 mt-2"></div>
              </template>
              <!--begin::Item-->
              <div class="d-flex flex-stack pb-8">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-35px me-4">
                    <a
                      v-if="item.link"
                      :href="item.link"
                      target="_blank"
                      :aria-label="item.title">
                      <span 
                        class="symbol-label" 
                        :style="`background-image: url('${item.image ? item.image : ''}');`">
                        {{ item.image ? '' : item.title[0] }}
                      </span>
                    </a>
                    <div v-else class="symbol-label">
                      <i class="ki-outline ki-ghost fs-2"></i>
                    </div>
                  </div>
            
                  <div class="mb-0 me-5">
                    <router-link
                      :to="`/search/${item.searchId}/items`"
                      :class="[
                        'fs-6 text-hover-primary fw-bold',
                        (item.link && item.platform && item.image) ? 'text-gray-800' : 'text-gray-600'
                      ]"
                    >
                      {{ item.title }}
                    </router-link>
                    <div
                      v-if="item.link && item.platform && item.image"
                      class="fs-7 text-gray-500"
                    >
                      {{ item.platform }}
                    </div>
                    <div v-else class="fs-7 text-gray-500">
                      {{ $t('pages.searchId.itemRemoved') }}
                    </div>
                  </div>
                </div>
                
                <div class="d-flex flex-column align-items-end">
                  <a
                    :href="item.link" target="_blank">
                    <PriceFormatter
                      :price="item.price"
                      :class="[
                        'text-nowrap fw-bold mb-0 fs-6',
                        item.link ? 'text-success text-hover-primary' : 'text-gray-600'
                      ]"
                    />
                  </a>
                  <DateFormatter 
                    :date="item.date"
                    :format="'H:i'"
                    class="text-nowrap text-gray-500 fw-semibold d-block fs-7"
                  />
                </div>
              </div>
              <!--end::Item-->
            </template>
            <!--end::Items-->
          </div>
          <!--end::Tab panel-->

          <!--begin::Tab panel-->
<!--           <div class="tab-pane fade" id="kt_topbar_alerts_2" role="tabpanel">
            <template v-for="(item, index) in data" :key="index">
              <div class="d-flex flex-stack py-4">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-35px me-4">
                    <span :class="`bg-light-${item.state}`" class="symbol-label">
                      <KTIcon
                        :icon-name="item.icon"
                        :icon-class="`text-${item.state}`"
                      />
                    </span>
                  </div>
          
                  <div class="mb-0 me-2">
                    <a
                      href="#"
                      class="fs-6 text-gray-800 text-hover-primary fw-bold"
                      >{{ item.title }}</a
                    >
                    <div class="text-gray-500 fs-7">
                      {{ item.description }}
                    </div>
                  </div>
                </div>
          
                <span class="badge badge-light fs-8">{{ item.time }}</span>
              </div>
            </template>
          
            <div class="py-3 text-center border-top">
              <a href="#" class="btn btn-color-gray-600 btn-active-color-primary">
                View All
                <KTIcon icon-name="arrow-right" icon-class="fs-5" />
              </a>
            </div>
          </div> -->
          <!--end::Tab panel-->
        
        </div>
        <!--end::Content-->
      </div>
      <!--end::Body-->
    </div>
  </div>
  <!--end::Alert drawer-->
</template>

<script lang="ts">
import { defineComponent, onMounted, nextTick, computed } from "vue";
import { defineStore } from "pinia";
import { useAlertStore } from '@/stores/alert';

import PriceFormatter from '@/components/formatters/PriceFormatter.vue';
import DateFormatter from '@/components/formatters/DateFormatter.vue';

export default defineComponent({
  name: "kt-alert-drawer",
  components: {
    PriceFormatter,
    DateFormatter,
  },
  setup() {
    const alertStore = useAlertStore();

    function isSameDate(date1: string | Date, date2: string | Date): boolean {
      const d1 = new Date(date1).toDateString();
      const d2 = new Date(date2).toDateString();
      return d1 === d2;
    }
    
    const sortedItems = computed(() => {
      return alertStore.items.slice().sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        if (dateA === dateB) {
          return b.id - a.id;
        }
        return dateB - dateA;
      });
    });
    
    onMounted(() => {
      nextTick(() => {
        alertStore.fetchData();
      });
    });

    const data = [
      {
        title: "Project Alice",
        description: "Phase 1 development",
        time: "1 hr",
        icon: "technology-2",
        state: "primary",
      },
      {
        title: "HR Confidential",
        description: "Confidential staff documents",
        time: "2 hrs",
        icon: "information-5",
        state: "danger",
      },
      {
        title: "Company HR",
        description: "Corporeate staff profiles",
        time: "5 hrs",
        icon: "briefcase",
        state: "warning",
      },
    ];

    return {
      sortedItems,
      isSameDate,
      data,
    };
  },
});
</script>
